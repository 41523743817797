// ==============================
// Override Variables
// 覆盖变量
// ==============================


$global-font-size: 22px;
$global-font-weight: 400;

$single-link-color: #727272;

$single-link-hover-color: #cacaca;

//$global-link-color: #727272;
$global-link-hover-color: #cacaca;
